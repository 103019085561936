import React from 'react'
import { number, string } from 'prop-types'
import './CloseIcon.scss'

const CloseIcon = ({ fontSize, color }) => {
  return (
    <svg fontSize={fontSize} width="24px" height="24px" viewBox="0 0 24 24" styleName="icon_сontainer">
      <g id="icon-close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M20,3.29289322 L3.29289322,20 L4,20.7071068 L20.7071068,4 L20,3.29289322 Z M20.7071068,20 L4,3.29289322 L3.29289322,4 L20,20.7071068 L20.7071068,20 Z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}

CloseIcon.defaultProps = {
  fontSize: 18,
  color: '#000',
}

CloseIcon.propTypes = {
  fontSize: number,
  color: string,
}

export default CloseIcon
