import React from 'react'
import { shape, arrayOf, string } from 'prop-types'
import './Footer.scss'
import PobosLogo from '../PobosLogo/PobosLogo'

const Footer = ({ links }) => {
  return (
    <div styleName="wrapper">
      <div styleName="inner_wrapper">
        <div styleName="logo">
          <PobosLogo />
        </div>
        <div styleName="copyrights-wrapper">
          <ul styleName="list">
            {links.map((link) => {
              return (
                <li key={link.name}>
                  <a styleName="links" target="_blank" rel="noopener noreferrer" href={link.linkName}>
                    {link.name}
                  </a>
                </li>
              )
            })}
          </ul>
          <div>© 1996 - {new Date().getFullYear()} McKinsey & Company</div>
        </div>
      </div>
    </div>
  )
}
Footer.defaultProps = {
  links: [],
}

Footer.propTypes = {
  links: arrayOf(
    shape({
      link: string,
      name: string,
    }),
  ),
}

export default Footer
