import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Element, scrollSpy } from 'react-scroll'
import { func, array, shape, string } from 'prop-types'
import Article from '../../components/Article/Article'
import SubArticle from '../../components/SubArticle/SubArticle'
import { PAGE_DATA_TYPE } from '../../components/RootComponent/utils'
import './ArticlePage.scss'

class ArticlePage extends Component {
  state = {}

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getArticleParams()
  }

  componentDidUpdate(prevProps) {
    const { data, match } = this.props
    if (prevProps.data !== data || prevProps.match !== match) {
      this.getArticleParams()
    }
    scrollSpy.update()
  }

  handleBack = () => {
    const { moveToCategory, history } = this.props
    const { category } = this.state
    if (category.id) {
      moveToCategory(category.id)
    } else {
      history.goBack()
    }
  }

  getArticleParams = () => {
    const { data, match, history } = this.props
    const article =
      data.find(
        (dataItem) =>
          (dataItem.type === PAGE_DATA_TYPE.ARTICLE || dataItem.type === PAGE_DATA_TYPE.NEWS_ARTICLE) &&
          String(dataItem.id) === match.params.id,
      ) || {}

    if (isEmpty(article)) {
      history.push('/error')
    }
    const subCategory =
      data.find((dataItem) => dataItem.type === PAGE_DATA_TYPE.SUBCATEGORY && dataItem.id === article.parentId) || {}
    const relatedArticles = data.filter(
      (dataItem) =>
        dataItem.type === PAGE_DATA_TYPE.ARTICLE && dataItem.parentId === subCategory.id && dataItem.id !== article.id,
    )
    const category =
      data.find((dataItem) => dataItem.type === PAGE_DATA_TYPE.CATEGORY && dataItem.id === subCategory.parentId) || {}

    if (article.type === PAGE_DATA_TYPE.NEWS_ARTICLE) {
      category.id = 'overview'
      category.linkName = 'Overview'
    }

    this.setState({ article, category, relatedArticles })
    document.title = `POBOS | ${article.name}`
  }

  handelClickArticle = (id) => {
    const { history } = this.props
    const { location } = history

    history.push(`/article/${id}${location.search}`)
  }

  render() {
    const { article, category, relatedArticles = [] } = this.state

    if (!article) {
      return null
    }
    return (
      <div>
        <Element name={`${category.id}`}>
          <Article
            name={article.name}
            content={article.content}
            imageSrc={article.image}
            description={article.description}
            handleBack={this.handleBack}
            backLinkName={category.linkName || category.name}
          >
            {relatedArticles.length !== 0 && <div styleName="relative_articles_title">Related</div>}
            <div styleName="relative_articles_container">
              {relatedArticles.map((articleItem) => (
                <div key={articleItem.id} styleName="relative_article">
                  <SubArticle {...articleItem} onClick={this.handelClickArticle} />
                </div>
              ))}
            </div>
          </Article>
        </Element>
      </div>
    )
  }
}

ArticlePage.propTypes = {
  data: array.isRequired,
  moveToCategory: func.isRequired,
  history: shape({
    push: func,
  }).isRequired,
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
}

export default ArticlePage
