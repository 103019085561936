import React from 'react'
import { string, func, node } from 'prop-types'
import ArrowIcon from '../Icons/ArrowIcon'
import './Article.scss'

const Article = ({ imageSrc, name, content, description, handleBack, backLinkName, children }) => {
  return (
    <div styleName="wrapper">
      {imageSrc ? <img src={imageSrc} alt="" styleName="head_image" /> : <div styleName="head_image" />}
      <div styleName="inner_wraper">
        <div styleName="text_wrapper">
          <div styleName="link" onClick={handleBack} onKeyDown={null} role="link" tabIndex={0}>
            <span styleName="arrow">
              <ArrowIcon />
            </span>{' '}
            {backLinkName}
          </div>
          <div styleName="header">{name}</div>
          <div styleName="description">{description}</div>
          {/* eslint-disable react/no-danger */}
          <div styleName="content" dangerouslySetInnerHTML={{ __html: content }} />
          {children}
        </div>
      </div>
    </div>
  )
}
Article.defaultProps = {
  imageSrc: null,
  description: null,
  handleBack: () => {},
  backLinkName: null,
  children: null,
  name: null,
  content: null,
}
Article.propTypes = {
  name: string,
  content: string,
  imageSrc: string,
  description: string,
  handleBack: func,
  backLinkName: string,
  children: node,
}

export default Article
