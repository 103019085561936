import React from 'react'
import { number, string } from 'prop-types'
import './AlertIcon.scss'

const AlertIcon = ({ fontSize, color }) => {
  return (
    <svg fontSize={fontSize} width="24px" height="24px" viewBox="0 0 24 24" styleName="icon_сontainer">
      <g id="Icon-alert" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M1.17181814,22 L12,1.42645447 L22.8281819,22 L1.17181814,22 Z M2.82818186,21 L21.1718181,21 L12,3.57354553 L2.82818186,21 Z M11,10 L13,10 L13,15 L11,15 L11,10 Z M12,18.5 C11.4477153,18.5 11,18.0522847 11,17.5 C11,16.9477153 11.4477153,16.5 12,16.5 C12.5522847,16.5 13,16.9477153 13,17.5 C13,18.0522847 12.5522847,18.5 12,18.5 Z"
          fill="#000000"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  )
}

AlertIcon.defaultProps = {
  fontSize: 18,
  color: '#000',
}

AlertIcon.propTypes = {
  fontSize: number,
  color: string,
}

export default AlertIcon
