import React from 'react'
import { number, string } from 'prop-types'
import './ArrowIcon.scss'

const ArrowIcon = ({ fontSize, color }) => {
  return (
    <svg fontSize={fontSize} viewBox="0 0 24 24" styleName="icon_сontainer">
      <g id="icon-arrow" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M19.7691204,11 L14.2236659,5.45454545 L15.6782113,4 L22.3080378,10.6298264 L22.6782113,11 L23,11 L23,11.3217887 L23.7813325,12.1031212 L23,12.8844537 L23,13 L22.8844537,13 L15.7813325,20.1031212 L14.3080378,18.6298264 L19.9378642,13 L1,13 C0.44771525,13 -1.08290132e-13,12.5522847 -1.08357767e-13,12 C-1.08425403e-13,11.4477153 0.44771525,11 1,11 L19.7691204,11 Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

ArrowIcon.defaultProps = {
  fontSize: 18,
  color: '#1F40E6',
}

ArrowIcon.propTypes = {
  fontSize: number,
  color: string,
}

export default ArrowIcon
