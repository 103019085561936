import { defaultEndPoint, errMessages } from './constants'
import 'whatwg-fetch'

export const getContent = ({ showHidden }) => {
  return fetch(`${defaultEndPoint}/content${showHidden ? '?showHidden=true' : ''}`).then((resp) => {
    const { status } = resp
    if (status >= 400) {
      throw new Error(errMessages.loadContent)
    }
    return resp.json()
  })
}

export const postEmail = (values) => {
  return fetch(`${defaultEndPoint}/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  }).then((res) => res.json())
}
