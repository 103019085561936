import React, { Component } from 'react'
import { array, func, shape, string } from 'prop-types'
import cn from 'classnames'
import { Element } from 'react-scroll'
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm'
import './MainPage.scss'
import Category from '../../components/Category/Category'
import SubcategoryCard from '../../components/SubcategoryCard/SubcategoryCard'
import MainArticles from '../../components/MainArticles/MainArticles'
import { postEmail } from '../../XHRUtils'
import { errMessages, formFreezeTime, mainTabTitle } from '../../constants'

class MainPage extends Component {
  state = {
    fetchingForm: false,
    submitSuccess: false,
    error: '',
  }

  componentDidMount() {
    const { moveToElement, location } = this.props
    if (location.hash && location.hash !== '') {
      moveToElement(location.hash)
    }
    document.title = mainTabTitle
  }

  componentWillUnmount() {
    clearTimeout(this.timerId)
  }

  handelLinkClick = (id, link) => {
    const { history } = this.props
    const { location } = history
    if (link) {
      window.open(link)
    } else {
      history.push(`article/${id}/${location.search}`)
    }
  }

  handelSubmit = async (values) => {
    this.setState({ fetchingForm: true, error: '' })
    try {
      const { statusCode, message } = await postEmail(values)
      if (statusCode >= 400) {
        throw new Error(message)
      }
      this.setState({ fetchingForm: false, error: '', submitSuccess: true })
    } catch (err) {
      this.setState({ error: errMessages.postEmail })
      setTimeout(() => this.setState({ fetchingForm: false }), formFreezeTime)
    }
  }

  render() {
    const { fetchingForm, error, submitSuccess } = this.state
    const { categories, newsArticles, emailPlaceholder, setRefs } = this.props

    return (
      <div>
        <Element name="overview">
          <div ref={setRefs('overview')}>
            <MainArticles items={newsArticles} onClickArticle={this.handelLinkClick} />
          </div>
        </Element>
        <div styleName="content_container">
          {categories.map((category, categoryIndex) => (
            <Element name={`${category.id}`} key={category.id}>
              <div styleName={cn('category_conteiner', 'category_conteiner_inner')}>
                {categoryIndex > 0 && category.content ? <div styleName="separator" /> : null}
                <Category
                  nodeRef={setRefs(category.id)}
                  content={category.content}
                  description={category.description}
                  name={category.name}
                />
              </div>

              <div styleName="subcategory_content">
                {category.subcategories.map((subcategory, index) => (
                  <div
                    key={subcategory.id}
                    styleName={cn('subcategory_card', {
                      small: index !== 0 && category.subcategories.length !== 2,
                      full_width: index === 0 && category.subcategories.length !== 2,
                      half_width: category.subcategories.length === 2,
                    })}
                  >
                    <SubcategoryCard
                      onLinkClick={this.handelLinkClick}
                      name={subcategory.name}
                      description={subcategory.description}
                      fullWidth={index === 0 && category.subcategories.length !== 2}
                      image={subcategory.image}
                      links={subcategory.articles}
                    />
                  </div>
                ))}
              </div>
            </Element>
          ))}
          <Element name="contactUs">
            <div styleName="category_conteiner" ref={setRefs('contactUs')}>
              <div styleName="form_container">
                <ContactUsForm
                  fetchingForm={fetchingForm}
                  error={error}
                  onSubmit={this.handelSubmit}
                  emailPlaceholder={emailPlaceholder}
                  submitSuccess={submitSuccess}
                  clearError={() => this.setState({ error: '' })}
                />
              </div>
            </div>
          </Element>
        </div>
      </div>
    )
  }
}

MainPage.propTypes = {
  location: shape({
    hash: string,
  }).isRequired,
  history: shape({
    push: func,
  }).isRequired,
  setRefs: func.isRequired,
  moveToElement: func.isRequired,
  categories: array.isRequired,
  newsArticles: array.isRequired,
  emailPlaceholder: string.isRequired,
}

export default MainPage
