import React from 'react'
import PropTypes from 'prop-types'
import './LinkToArticle.scss'
import ArrowIcon from '../Icons/ArrowIcon'
import { PAGE_DATA_TYPE } from '../RootComponent/utils'

const LinkToArticle = ({ name, id, onClick, type, linkName, color }) => {
  const handelClick = () => {
    let externalLink
    if (type === PAGE_DATA_TYPE.EXTERNAL_NEWS_ARTICLE) {
      externalLink = linkName
    }
    onClick(id, externalLink)
  }

  const colorStyles = color ? { color } : {}

  return (
    <button styleName="container" onClick={handelClick} type="button">
      <span styleName="name_container" style={colorStyles}>
        {name}
      </span>
      <span styleName="icon_container">
        <ArrowIcon />
      </span>
    </button>
  )
}

LinkToArticle.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  linkName: PropTypes.string,
  color: PropTypes.string.isRequired,
}
LinkToArticle.defaultProps = {
  linkName: undefined,
}

export default LinkToArticle
