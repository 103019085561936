import React, { useState, useEffect, useCallback, useRef } from 'react'
import { number, node, bool, func } from 'prop-types'
import cn from 'classnames'
import { Transition } from 'react-transition-group'
import './SliderItem.scss'

const transitionClasses = {
  entered: 'show',
  exited: 'hide',
}

const SliderItem = ({ open, transitionTime, children, getInnerHeight, width }) => {
  const [show, setShow] = useState(false)
  const element = useRef(null)

  useEffect(() => {
    setShow(open)
  }, [])

  useEffect(() => {
    if (show && element.current && open) {
      getInnerHeight(element.current.getBoundingClientRect().height)
    }
  }, [show, width])

  const handelEnter = useCallback(() => {
    setShow(true)
  }, [show])

  const handelExit = useCallback(() => {
    setShow(false)
  }, [show])

  return (
    <Transition in={open} timeout={transitionTime} onEnter={handelEnter} onExited={handelExit}>
      {(transitionState) =>
        show ? (
          <div
            ref={element}
            style={{ transition: `opacity ${transitionTime}ms` }}
            styleName={cn('item_container', transitionClasses[transitionState])}
          >
            {children}
          </div>
        ) : null
      }
    </Transition>
  )
}

SliderItem.propTypes = {
  width: number.isRequired,
  children: node.isRequired,
  transitionTime: number.isRequired,
  open: bool.isRequired,
  getInnerHeight: func.isRequired,
}

export default SliderItem
