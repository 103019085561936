import React from 'react'
import { shape, string, number, arrayOf, func } from 'prop-types'
import LinkToArticle from '../SubcategoryCard/LinkToArticle'
import Slider from '../Slider/Slider'
import './MainArticles.scss'

const MainArticles = ({ items, onClickArticle }) => {
  return (
    <div styleName="container">
      <Slider>
        {items.map(({ id, name, description, image, type, linkName, textColor }) => (
          <div key={id} styleName="slide">
            <div styleName="bg_img" style={{ backgroundImage: `url(${image})` }} />
            <div styleName="content">
              <div styleName="name" style={{ color: textColor }}>
                {name}
              </div>
              <div styleName="description" style={{ color: textColor }}>
                {description}
              </div>

              <LinkToArticle
                name="See full story"
                id={id}
                color={textColor}
                onClick={onClickArticle}
                type={type}
                linkName={linkName}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

MainArticles.propTypes = {
  items: arrayOf(
    shape({
      name: string.isRequired,
      description: string.isRequired,
      id: number.isRequired,
      image: string.isRequired,
    }),
  ).isRequired,
  onClickArticle: func.isRequired,
}

export default MainArticles
