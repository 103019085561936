import React, { useEffect } from 'react'
import { pobosWebPage, supportEmail, mainTabTitle } from '../../constants'
import './ErrorPage.scss'

const ErrorPage = () => {
  useEffect(() => {
    document.title = mainTabTitle
  }, [])
  return (
    <div styleName="conatiner">
      <div styleName="title">Oops! Something went wrong</div>
      <div styleName="description">The page you are looking for might have been moved or renamed</div>
      <div styleName="content">
        We apologize for any inconvenience. Please double-check the URL or try the following:
      </div>
      <div styleName="content">
        <div>
          Visit <a href={pobosWebPage}>POBOS home page</a> or use the navigation menu to find what you need.{' '}
        </div>
        <a href={`mailto:${supportEmail}`}>Contact us by email</a> if you still have problem.
      </div>
    </div>
  )
}

export default ErrorPage
