import React from 'react'
import { string, func, number } from 'prop-types'
import LinkToArticle from '../SubcategoryCard/LinkToArticle'
import './SubArticle.scss'

const SubArticle = ({ image, name, description, onClick, id }) => {
  return (
    <div>
      {image ? (
        <div styleName="inner_container">
          <img styleName="image" src={image} alt="" />
        </div>
      ) : null}
      <div styleName="title_container">{name}</div>
      <div styleName="description_container">{description}</div>
      <LinkToArticle name="Read More" onClick={onClick} id={id} />
    </div>
  )
}

SubArticle.defaultProps = {
  description: '',
  image: null,
}

SubArticle.propTypes = {
  onClick: func.isRequired,
  name: string.isRequired,
  image: string,
  description: string,
  id: number.isRequired,
}

export default SubArticle
