import React, { useState } from 'react'
import { func, arrayOf, shape, string } from 'prop-types'
import cn from 'classnames'
import './RadioGroupStyle.scss'

const RadioGroup = ({ onChange, options }) => {
  const [selectedOption, setOption] = useState(options && options[0] && options[0].option)

  const handleOptionChange = (event) => {
    setOption(event.target.value)
    onChange(event.target.value)
  }

  return (
    <div styleName="container">
      {options.map(({ option, label }) => (
        <label key={option} styleName="label">
          <input
            styleName="hided_input"
            type="radio"
            value={option}
            checked={selectedOption === option}
            onChange={handleOptionChange}
          />
          <div
            styleName={cn('masked_input', {
              checked: selectedOption === option,
            })}
          />
          <span styleName="text_container">{label}</span>
        </label>
      ))}
    </div>
  )
}

RadioGroup.propTypes = {
  onChange: func.isRequired,
  options: arrayOf(
    shape({
      option: string,
      label: string,
    }),
  ).isRequired,
}

export default RadioGroup
