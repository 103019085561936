import isEmpty from 'lodash/isEmpty'

// eslint-disable-next-line no-useless-escape
const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const required = (value) => (isEmpty(value) ? 'The field cannot be empty' : undefined)
export const emailRequired = (value) => (emailRegexp.test(value) ? undefined : 'Email is invalid')
const fieldsValidators = {
  email: [required, emailRequired],
  phone: [required],
  name: [required],
}
const filterConfig = {
  email: 'email',
  phone: 'phone',
}

export const checkValidators = (name, value) => {
  const validators = fieldsValidators[name]
  if (isEmpty(validators)) {
    return undefined
  }

  let error
  validators.some((validatorFunc) => {
    error = validatorFunc(value)
    return Boolean(error)
  })
  return error
}

export const isValidForm = (values, phoneEmailType) => {
  return Object.keys(fieldsValidators)
    .filter((fieldName) => !filterConfig[fieldName] || filterConfig[fieldName] === phoneEmailType)
    .every((fieldName) => {
      const validators = fieldsValidators[fieldName]
      const value = values[fieldName]

      return validators.every((validatorFunc) => validatorFunc(value) === undefined)
    })
}

export const omitKey = (obj, key) => {
  return Object.keys(obj)
    .filter((objKey) => objKey !== key)
    .reduce((resultObj, objKey) => ({ ...resultObj, [objKey]: obj[objKey] }), {})
}
