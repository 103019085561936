import { func, bool, string } from 'prop-types'
import React from 'react'
import './Button.scss'

const Button = ({ disabled, onClick, children }) => (
  <button type="submit" styleName="btn" disabled={disabled} onClick={onClick}>
    {children}
  </button>
)

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
}

Button.propTypes = {
  onClick: func,
  disabled: bool,
  children: string.isRequired,
}

export default Button
