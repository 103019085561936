import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { func, shape, string } from 'prop-types'
import { scroller, scrollSpy } from 'react-scroll'
import MainPage from '../../pages/MainPage/MainPage'
import ArticlePage from '../../pages/ArticlePage/ArticlePage'
import ErrorPage from '../../pages/ErrorPage/ErrorPage'
import './RootComponent.scss'
import { parseData, normalizeImgSrc, parseUrlParams } from './utils'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { getContent } from '../../XHRUtils'

class RootComponent extends Component {
  state = {
    categories: [],
    newsArticles: [],
    menuItems: [],
    data: [],
    policyArticles: [],
    fetching: true,
    emailPlaceholder: '',
  }

  nodes = {}

  async componentDidMount() {
    try {
      const pageData = await getContent({ showHidden: this.getShowHiddenParam() })
      const normalizedData = normalizeImgSrc(pageData)
      this.setState({ ...parseData(normalizedData), data: normalizedData, fetching: false })
    } catch (err) {
      const { history } = this.props
      this.setState({ fetching: false })
      history.push('/error')
    }
  }

  componentDidUpdate() {
    scrollSpy.update()
  }

  getShowHiddenParam = () => {
    const { location } = this.props
    const { search } = location || {}
    const { showHidden = false } = parseUrlParams(search)

    return showHidden
  }

  moveToElement = (hash) => {
    scroller.scrollTo(hash.replace('#', ''), {
      offset: -90,
      isDynamic: true,
    })
  }

  handelClickLink = (id) => {
    const { history } = this.props
    const { location } = history
    history.push(`/${location.search}#${id}`)
  }

  setRefs = (id) => (node) => {
    this.nodes[`${id}`] = node
  }

  mainPageComponent = (props) => {
    const { categories, newsArticles, emailPlaceholder } = this.state

    return (
      <MainPage
        {...props}
        setRefs={this.setRefs}
        categories={categories}
        newsArticles={newsArticles}
        moveToElement={this.moveToElement}
        emailPlaceholder={emailPlaceholder}
      />
    )
  }

  articlePageComponent = (props) => {
    const { data } = this.state

    return <ArticlePage {...props} data={data} moveToCategory={this.handelClickLink} />
  }

  render() {
    const { menuItems, fetching, policyArticles } = this.state
    const { history } = this.props

    if (fetching) {
      return null
    }

    return (
      <div styleName="main_container">
        <div styleName="header_container">
          <Header
            items={menuItems}
            onClickLink={this.handelClickLink}
            isPreview={this.getShowHiddenParam()}
            history={history}
          />
        </div>
        <div styleName="body_container">
          <Switch>
            <Route component={this.articlePageComponent} path="/article/:id" />
            <Route component={ErrorPage} path="/error" />
            <Route component={this.mainPageComponent} path="/" />
          </Switch>
        </div>
        <div styleName="footer_container">
          <Footer links={policyArticles} />
        </div>
      </div>
    )
  }
}

RootComponent.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
  location: shape({
    hash: string,
    pathname: string,
    search: string,
  }).isRequired,
}

export default withRouter(RootComponent)
