/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { number, node } from 'prop-types'
import './Slider.scss'
import SliderItem from './SliderItem'
import SliderNavBar from './SliderNavBar'

let timerId
const SM = 768

const useRefState = (stateArr) =>
  stateArr.map((stateItem) => {
    const refState = useRef(stateItem)
    refState.current = stateItem

    return refState
  })

const Slider = ({ children, transitionTime, barTransitionTime }) => {
  const [currentElemIndex, setCurrentElementIndex] = useState(0)
  const [autoPlay, setAutoPlay] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(600)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [currentElemIndexRef, widthRef, autoPlayRef] = useRefState([currentElemIndex, width, autoPlay])
  const { length } = children

  const getSizeFromWidth = useCallback(() => {
    setWidth(window.innerWidth)
  }, [width])

  const handelNextSlide = useCallback(
    (event) => {
      if (currentElemIndexRef.current < length - 1) {
        setCurrentElementIndex(currentElemIndexRef.current + 1)
      } else {
        setCurrentElementIndex(0)
      }

      clearTimeout(timerId)

      if (widthRef.current > SM && autoPlayRef.current) {
        timerId = setTimeout(handelNextSlide, barTransitionTime)
      }
    },
    [currentElemIndex],
  )

  const handelPrevSlide = useCallback(() => {
    if (currentElemIndexRef.current !== 0) {
      setCurrentElementIndex(currentElemIndexRef.current - 1)
    } else {
      setCurrentElementIndex(length - 1)
    }
  }, [currentElemIndex])

  const moveToSlide = useCallback(
    (index) => {
      clearTimeout(timerId)
      setCurrentElementIndex(index)
      setAutoPlay(false)
    },
    [currentElemIndex],
  )
  const debouncedGetSize = debounce(getSizeFromWidth, 500)

  useEffect(() => {
    window.addEventListener('resize', debouncedGetSize, false)
    getSizeFromWidth()

    return () => {
      window.removeEventListener('resize', debouncedGetSize, false)
    }
  }, [])

  useEffect(() => {
    if (length > 1 && width > SM && autoPlay) {
      timerId = setTimeout(handelNextSlide, barTransitionTime)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [width])

  const getInnerHeight = useCallback(
    (innerHeight) => {
      setHeight(innerHeight)
    },
    [height],
  )

  const onTouchStart = useCallback(
    (event) => {
      const { clientX: x, clientY: y } = event.touches[0] || {}
      setPosition({ x, y })
    },
    [position],
  )

  const onTouchEnd = useCallback(
    (event) => {
      const { clientX, clientY } = event.changedTouches[0] || {}
      const { x, y } = position

      const deltaX = clientX - x
      const deltaY = clientY - y

      if (Math.abs(deltaX) > Math.abs(deltaY) * 2) {
        if (deltaX < 0) {
          handelNextSlide()
        } else {
          handelPrevSlide()
        }
      }

      setAutoPlay(false)
    },
    [position],
  )

  return (
    <div styleName="container" style={{ height: `${height}px` }} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
      {length > 1 ? (
        <SliderNavBar currentElemIndex={currentElemIndex} length={length} moveToSlide={moveToSlide} />
      ) : null}
      {children.map((childrenItem, index) => (
        <SliderItem
          width={width}
          key={index}
          getInnerHeight={getInnerHeight}
          open={index === currentElemIndex}
          index={index}
          transitionTime={transitionTime}
        >
          {childrenItem}
        </SliderItem>
      ))}
    </div>
  )
}

Slider.defaultProps = {
  transitionTime: 1000,
  barTransitionTime: 10000,
  children: [],
}

Slider.propTypes = {
  children: node,
  transitionTime: number,
  barTransitionTime: number,
}

export default Slider
