export const defaultEndPoint = `${process.env.API_SERVER}/page-api`
export const pobosWebPage = `${process.env.API_SERVER}/webpage`
export const mckinseyWebPage = process.env.MCK_HOST
export const supportEmail = process.env.SUPPORT_EMAIL
export const errMessages = {
  loadContent: 'Unable to load content',
  postEmail: 'Unable to post email form',
}
export const formFreezeTime = 5000
export const scrollDuration = 500
export const mainTabTitle = 'POBOS'
