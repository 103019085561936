import React, { useState, Fragment } from 'react'
import { func, string, bool } from 'prop-types'
import TextInput, { INPUT_TYPE } from '../TextInput/TextInput'
import Button from '../Button/Button'
import RadioGroup from '../RadioGroup/RadioGroup'
import { omitKey, checkValidators, isValidForm } from './utils'
import AlertIcon from '../Icons/AlertIcon'
import CloseIcon from '../Icons/CloseIcon'
import './ContactUsForm.scss'

const radioGroupOptions = [
  { option: 'email', label: 'By email' },
  { option: 'phone', label: 'By phone' },
]
const EMAIL = 'email'
const PHONE = 'phone'

const ContactUsForm = ({ onSubmit, emailPlaceholder, fetchingForm, error, clearError, submitSuccess }) => {
  const [state, setState] = useState({
    phoneEmailType: 'email',
    values: {},
    errors: {},
    touch: {},
    selected: {},
  })

  const handleSubmit = () => {
    const { values, phoneEmailType } = state
    const { text } = values
    let fullValues = values
    if (!text) {
      fullValues = { ...values, text: emailPlaceholder }
    }

    onSubmit(omitKey(fullValues, phoneEmailType === EMAIL ? PHONE : EMAIL))
  }

  const handleChangeInput = (event) => {
    const { value, name } = event.target

    setState({
      ...state,
      values: { ...state.values, [name]: value },
      errors: { ...state.errors, [name]: checkValidators(name, value) },
    })
  }

  const handleBlurInput = (event) => {
    const { value, name } = event.target

    setState({
      ...state,
      errors: { ...state.errors, [name]: checkValidators(name, value) },
      touch: { ...state.errors, [name]: true },
      selected: { ...state.errors, [name]: false },
    })
  }

  const handleFocusInput = (event) => {
    const { name } = event.target

    setState({
      ...state,
      selected: { ...state.errors, [name]: true },
    })
  }

  const handleChangeRadioGroup = (type) => {
    setState({ ...state, phoneEmailType: type })
  }

  const { phoneEmailType, values, errors, touch, selected } = state

  return (
    <div styleName="container">
      <div styleName="title">Contact POBOS</div>
      {!submitSuccess && (
        <Fragment>
          <div styleName="item">
            <TextInput
              label="Full Name"
              value={values.name}
              selected={selected.name}
              name="name"
              onChange={handleChangeInput}
              error={touch.name && errors.name}
              onBlur={handleBlurInput}
              onFocus={handleFocusInput}
            />
          </div>
          <div styleName="item">
            <div styleName="sub_title">How to contact you?</div>
            <div styleName="radio_group_container">
              <RadioGroup options={radioGroupOptions} onChange={handleChangeRadioGroup} />
            </div>
            {phoneEmailType === PHONE ? (
              <TextInput
                key="phone"
                label="Phone"
                name="phone"
                value={values.phone}
                selected={selected.phone}
                onChange={handleChangeInput}
                error={touch.phone && errors.phone}
                onBlur={handleBlurInput}
                onFocus={handleFocusInput}
              />
            ) : (
              <TextInput
                key="email"
                label="E-mail"
                name="email"
                value={values.email}
                selected={selected.email}
                onChange={handleChangeInput}
                error={touch.email && errors.email}
                onBlur={handleBlurInput}
                onFocus={handleFocusInput}
              />
            )}
          </div>
          <div styleName="item">
            <div styleName="sub_title">
              How can we help you? <span styleName="option">(Optional)</span>
            </div>
            <div styleName="additional_info">Tell us a little about your company and what you interested in</div>
            <TextInput
              name="text"
              value={values.text}
              selected={selected.text}
              onChange={handleChangeInput}
              type={INPUT_TYPE.TEXT_AREA}
              error={touch.text && errors.text}
              onBlur={handleBlurInput}
              placeholder={emailPlaceholder}
              onFocus={handleFocusInput}
            />
          </div>
          <div styleName="company_info">
            McKinsey & Company is committed to protecting your information. Your information will be used in accordance
            with the applicable data privacy law, our internal policies and our privacy policy. As McKinsey is a global
            organization, your information may be stored and processed by McKinsey and its affiliates in countries
            outside your country of residence, but wherever your information is processed, we will handle it with the
            same care and respect for your privacy.
          </div>
          <div styleName="footer_cnt">
            <Button onClick={handleSubmit} disabled={!isValidForm(values, phoneEmailType) || fetchingForm}>
              Submit
            </Button>
            {error && (
              <div styleName="error_cnt">
                <div>
                  <AlertIcon fontSize={24} />
                </div>
                <div styleName="err_text">{error}</div>
                <button styleName="hide_err" type="button" onClick={clearError}>
                  <CloseIcon fontSize={24} />
                </button>
              </div>
            )}
          </div>
        </Fragment>
      )}
      {submitSuccess && <div styleName="success_text">Thank you for contacting POBOS</div>}
    </div>
  )
}

ContactUsForm.propTypes = {
  onSubmit: func.isRequired,
  clearError: func.isRequired,
  emailPlaceholder: string.isRequired,
  fetchingForm: bool.isRequired,
  submitSuccess: bool.isRequired,
  error: string.isRequired,
}

export default ContactUsForm
