import React from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import RootComponent from './components/RootComponent/RootComponent'

export const history = createBrowserHistory({ basename: process.env.BASE_PATH })

export const App = () => (
  <Router history={history}>
    <RootComponent />
  </Router>
)

export default App
