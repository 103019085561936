import React from 'react'
import cn from 'classnames'
import { number, oneOf, string, func, bool } from 'prop-types'
import './TextInput.scss'

export const INPUT_TYPE = {
  TEXT_INPUT: 'TEXT_INPUT',
  TEXT_AREA: 'TEXT_AREA',
}

const TextInput = ({ value, name, error, onChange, label, type, onBlur, rows, placeholder, selected, onFocus }) => {
  const propInput = {
    name,
    value,
    onChange,
    onBlur,
    placeholder,
    onFocus,
  }

  return (
    <div>
      {label && <label styleName={cn('label_style', { error_text: !!error })}>{label}</label>}
      <div
        styleName={cn('input_container', {
          error_input_container: !!error,
          default_input_styles: !error,
          selected_input_container: !error && selected,
        })}
      >
        {type === INPUT_TYPE.TEXT_INPUT ? (
          <input type="text" {...propInput} styleName="input" />
        ) : (
          <textarea rows={rows} {...propInput} styleName="input" />
        )}
      </div>
      {error && <div styleName={cn('error_text', 'helper_text')}>{error}</div>}
    </div>
  )
}

TextInput.defaultProps = {
  error: null,
  type: INPUT_TYPE.TEXT_INPUT,
  rows: 4,
  label: null,
  value: '',
  placeholder: '',
  selected: false,
  onFocus: () => {},
}

TextInput.propTypes = {
  rows: number,
  type: oneOf([INPUT_TYPE.TEXT_INPUT, INPUT_TYPE.TEXT_AREA]),
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func,
  value: string,
  name: string.isRequired,
  error: string,
  label: string,
  placeholder: string,
  selected: bool,
}

export default TextInput
