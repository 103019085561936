import React from 'react'
import { string, func } from 'prop-types'
import './Category.scss'

const Category = ({ name, content, description, nodeRef }) => {
  return (
    <div>
      {content && <div styleName="content">{content}</div>}
      <div styleName="description" ref={nodeRef}>
        {description}
      </div>
      <div styleName="name">{name}</div>
    </div>
  )
}

Category.defaultProps = {
  content: null,
  description: null,
  nodeRef: () => {},
}

Category.propTypes = {
  name: string.isRequired,
  content: string,
  description: string,
  nodeRef: func,
}

export default Category
