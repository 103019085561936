import { defaultEndPoint } from '../../constants'

export const PAGE_DATA_TYPE = {
  CATEGORY: 'CATEGORY',
  ARTICLE: 'ARTICLE',
  SUBCATEGORY: 'SUBCATEGORY',
  NEWS_ARTICLE: 'NEWS_ARTICLE',
  EXTERNAL_NEWS_ARTICLE: 'EXTERNAL_NEWS_ARTICLE',
  POLICY_ARTICLE: 'POLICY_ARTICLE',
  EMAIL_PLACEHOLDER: 'EMAIL_PLACEHOLDER',
}

const CONTACT_US_ITEM = {
  linkName: 'Contact us',
  id: 'contactUs',
}
const OVERVIEW_ITEM = {
  linkName: 'Overview',
  id: 'overview',
}

export const parseData = (pageData) => {
  const categories = []
  const newsArticles = []
  const menuItems = []
  const policyArticles = []
  let emailPlaceholder = ''

  if (!pageData) {
    return { categories, newsArticles, menuItems: [OVERVIEW_ITEM, CONTACT_US_ITEM], policyArticles, emailPlaceholder }
  }
  const articles = {}

  pageData.forEach((item) => {
    if (item.type === PAGE_DATA_TYPE.ARTICLE) {
      if (articles[item.parentId]) {
        articles[item.parentId].push(item)
      } else {
        articles[item.parentId] = [item]
      }
    }
  })

  pageData.forEach((item) => {
    if (item.type === PAGE_DATA_TYPE.CATEGORY) {
      categories.push({
        ...item,
        subcategories: [],
      })
      if (item.linkName) {
        menuItems.push({
          linkName: item.linkName,
          id: item.id,
          description: item.description,
        })
      }
    }
    if (item.type === PAGE_DATA_TYPE.SUBCATEGORY) {
      const category = categories.find(({ id }) => id === item.parentId)
      if (category) {
        category.subcategories.push({
          ...item,
          articles: articles[item.id] || [],
        })
      }
    }
    if ([PAGE_DATA_TYPE.NEWS_ARTICLE, PAGE_DATA_TYPE.EXTERNAL_NEWS_ARTICLE].includes(item.type)) {
      newsArticles.push(item)
    }
    if (item.type === PAGE_DATA_TYPE.POLICY_ARTICLE) {
      policyArticles.push(item)
    }
    if (item.type === PAGE_DATA_TYPE.EMAIL_PLACEHOLDER) {
      emailPlaceholder = item.description
    }
  })

  return {
    categories,
    newsArticles,
    menuItems: [OVERVIEW_ITEM, ...menuItems, CONTACT_US_ITEM],
    policyArticles,
    emailPlaceholder,
  }
}

export const normalizeImgSrc = (pageData) =>
  pageData.map(({ image, content, ...rest }) => {
    let contentWithSrc = content
    const normalizedImage = image ? `${defaultEndPoint}/image/${image}` : image
    if (content && content.indexOf('<img') !== -1) {
      contentWithSrc = content.replace(/<img src="(.+?)"/gi, (str, p1) => `<img src="${defaultEndPoint}/image/${p1}"`)
    }

    return { ...rest, image: normalizedImage, content: contentWithSrc }
  })

const queryValueMap = {
  true: true,
  false: false,
}
export const parseUrlParams = (queryString) => {
  const params = queryString
    .replace(/^\?/, '')
    .split('&')
    .reduce((accum, item) => {
      const [key, val] = item.split('=')
      return { ...accum, [key]: queryValueMap[val] || val }
    }, {})
  return params
}
