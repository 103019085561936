import React from 'react'
import cn from 'classnames'
import { oneOfType, func, string, number, bool, arrayOf, shape } from 'prop-types'
import './SubcategoryCard.scss'
import LinkToArticle from './LinkToArticle'

const SubcategoryCard = ({ name, description, image, fullWidth, links, onLinkClick }) => {
  const mediana = Math.ceil(links.length / 2)

  return (
    <div styleName={cn('card_container', { sm: !fullWidth })}>
      <div styleName="image_container">
        {image ? (
          <div styleName="inner_img_container">
            <img styleName="image" src={image} alt="" />
          </div>
        ) : null}
      </div>
      <div styleName="main_description_container">
        <div styleName="title_container">{name}</div>
        <div styleName="description_container">{description}</div>
        <div styleName="links_container">
          <div styleName="links_item_container">
            {links.slice(0, mediana).map(({ id, linkName }) => (
              <LinkToArticle key={id} name={linkName} id={id} onClick={onLinkClick} />
            ))}
          </div>
          <div styleName="links_item_container">
            {links.slice(mediana).map(({ id, linkName }) => (
              <LinkToArticle key={id} name={linkName} id={id} onClick={onLinkClick} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

SubcategoryCard.defaultProps = {
  fullWidth: false,
  image: null,
}

SubcategoryCard.propTypes = {
  onLinkClick: func.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  fullWidth: bool,
  image: string,
  links: arrayOf(
    shape({
      id: oneOfType([number, string]),
      name: string,
    }),
  ).isRequired,
}

export default SubcategoryCard
