import React, { useState, useEffect, useRef } from 'react'
import { shape, string, number, arrayOf, oneOfType, bool, func } from 'prop-types'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import { Link as AnchorLink, Events } from 'react-scroll'
import { scrollDuration } from '../../constants'

import PobosLogo from '../PobosLogo/PobosLogo'

import styles from './Header.scss'

const MD = 960
const emptyFunc = () => {}

const Header = (props) => {
  const { items, isPreview, history } = props
  const { location } = history
  const [isOpened, setOpened] = useState(false)

  const node = useRef(null)

  const toggleOpen = () => {
    setOpened(!isOpened)
  }

  const handelOnClick = (id) => () => {
    const { onClickLink } = props
    if (isOpened) {
      toggleOpen()
    }
    return onClickLink(id)
  }

  const handelResize = () => {
    if (window.innerWidth > MD) {
      setOpened(false)
    }
  }

  const handleOutsideClick = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setOpened(false)
    }
  }
  const debouncedResize = debounce(handelResize, scrollDuration)

  useEffect(() => {
    window.addEventListener('resize', debouncedResize, false)
    document.addEventListener('click', handleOutsideClick, false)
    return () => {
      window.removeEventListener('resize', debouncedResize, false)
      Events.scrollEvent.remove('end')
    }
  }, [])

  const onSetActive = (id) => () => {
    const { search, pathname } = location
    history.push(`${pathname}${search}#${id}`)
  }

  return (
    <header ref={node} styleName={cn('wrapper', { is_opened: isOpened })}>
      <div styleName="inner_wrapper">
        {isPreview && <div styleName="preview_container">Preview mode</div>}
        <div styleName="img_cnt">
          <div styleName="menu_cover" onClick={toggleOpen} role="button" tabIndex="0" onKeyDown={emptyFunc}>
            <div styleName={cn('hamburger', { is_active: isOpened })}></div>
          </div>
          <PobosLogo color={isOpened ? '#fff' : '#000'} />
        </div>
        <div styleName="list_container">
          <nav styleName="list_cnt">
            <div styleName="list_label">POBOS</div>
            {items.map(({ id, linkName, description }, index) => (
              <AnchorLink
                key={id}
                to={`${id}`}
                onSetActive={onSetActive(id)}
                activeClass={styles.active}
                spy
                offset={-90}
              >
                <div styleName="item" onClick={handelOnClick(id)} role="button" tabIndex="0" onKeyDown={emptyFunc}>
                  <div styleName="inner_item">{linkName}</div>
                </div>
              </AnchorLink>
            ))}
          </nav>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  isPreview: false,
}

Header.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
  onClickLink: func.isRequired,
  isPreview: bool,
  items: arrayOf(
    shape({
      linkName: string,
      description: string,
      id: oneOfType([number, string]),
    }),
  ).isRequired,
}

export default Header
