import React from 'react'
import './SliderNavBar.scss'
import cn from 'classnames'
import { number, func } from 'prop-types'

const emptyFunc = () => {}

const SliderNavBar = ({ currentElemIndex, length, moveToSlide }) => {
  const handelDotClick = (index) => () => {
    moveToSlide(index)
  }

  return (
    <div styleName="dots_container">
      {[...Array(length)].map((item, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          styleName={cn('dot', currentElemIndex === index ? 'selected_dot' : 'default_dot')}
          onClick={handelDotClick(index)}
          role="button"
          tabIndex="0"
          onKeyDown={emptyFunc}
        ></div>
      ))}
    </div>
  )
}

SliderNavBar.propTypes = {
  currentElemIndex: number.isRequired,
  length: number.isRequired,
  moveToSlide: func.isRequired,
}

export default SliderNavBar
